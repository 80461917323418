import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/Layout/Layout'
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import Highlight from 'react-highlight'
// markup
const ApiReference = () => {
  useLayoutEffect(() => {}, [])

  return (
    <Layout>
      <title>Consupedia - Api reference</title>
      {/* <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Webhook</h1>
          </div>
        </div>
      </div> */}
      <section className="alignfull sidebar-page">
        <div className="nav">
          <>
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId="/webhook/#about"
              onSelect={({ itemId }) => {
                // maybe push to the route
                navigate(itemId)
              }}
              items={[
                {
                  title: 'API reference',
                  itemId: '/api-reference/#api-reference',
                  subNav: [
                    {
                      title: 'Get started',
                      itemId: '/api-reference/#api-reference'
                    },
                    {
                      title: 'Authorization',
                      itemId: '/api-reference/#authorization'
                    },
                    {
                      title: 'Connect API',
                      itemId: '/api-reference/#connect-api'
                    },
                    {
                      title: 'Product API',
                      itemId: '/api-reference/#product-api'
                    }
                  ]
                }
              ]}
            />
          </>
        </div>
        <div className="section-container">
          <div id="api-reference">
            <h2>RESTful API Reference</h2>
            <p>
              It's wonderful to know that you're interested in Consupedia's
              RESTful API. This guide will provide you with an overview of the
              fundamental steps needed to begin utilizing the API, allowing you
              to access sustainability data for a wide range of food products.
              <br />
              <br /> Contact{' '}
              <a href="mailto:tech-support@consupedia.com">
                tech-support@consupedia.com
              </a>{' '}
              to obtain an API-key.
            </p>
            <span className="spacer" id="authorization"></span>
            <h2>Authorization</h2>
            <p>
              To authorize your requests, you'll need to use an API key. The key
              should be included in the header of your requests as follows:
            </p>
            <p>
              Header:
              <br />
              Key: X-API-KEY
              <br />
              Value: [your_api_key]
            </p>
            <p>
              To make a sample cURL request using the provided authorization,
              replace [your_api_key] with your actual API key:
            </p>
            <h3>cURL example</h3>
            <Highlight language={['sh']}>
              curl -X GET 'https://connect.consupedia.com/api/v2/...' -H
              'X-API-KEY: your_api_key'
            </Highlight>
            <h3>Python example</h3>
            <Highlight language={['python']}>
              {`import requests

url = 'https://connect.consupedia.com/api/v2/...'
headers = {
    'X-API-KEY': 'your_api_key'
}

response = requests.get(url, headers=headers)
`}
            </Highlight>
            <h3>Javascript example</h3>
            <Highlight languages={['javascript']}>
              {`const axios = require('axios');

const url = 'https://connect.consupedia.com/api/v2/...';
const headers = {
  'X-API-KEY': 'your_api_key'
};

axios.get(url, { headers })
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error(error);
  });`}
            </Highlight>
            <span className="spacer" id="connect-api"></span>
            <h2>Connect API</h2>
            <p>
              Connect API is our RESTful service for subscribing and querying
              sustainability data for specific products. Subscriptions can be
              used together with our <a href="/webhook">webhook service</a>.
            </p>
            <br /> Uses cases:
            <p>
              - Enterprise data enrichment
              <br />- Producer sustainability tracking
            </p>
            <Highlight>
              Base url: https://connect.consupedia.com/api/v2/
            </Highlight>
            <a href="/api-reference/connect">See documentation.</a> <br />
            <br />
            <span className="spacer" id="product-api"></span>
            <h2>Product API</h2>
            <p>
              Product API is our RESTful service for querying general
              information as well as sustainability parameters about products.
              It is not for commercial use and is aimed at research projects.
              <br />
              <a href="https://consupedia.com/team/" target="_blank">
                Contact us for more information
              </a>
              .
            </p>
            <Highlight>Base url: https://api.consupedia.com/v3/</Highlight>
            <a href="/docs">See documentation here.</a>
            <br />
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>You need any help with the Consupedia platform? Get in touch:</p>
            <p>
              <strong>
                <a href="mailto:tech-support@consupedia.com">
                  tech-support@consupedia.com
                </a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ApiReference
